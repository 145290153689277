import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/form-control.scss'

const FormControl = ({ label, invalid, error, hint, noMargin, className, children }) => (
  <div className={cn(
    className,
    'form-control',
    { '-invalid': invalid },
    { '-no-margin': noMargin },
  )}>
    {label &&
      <label className="form-control_label">{label}</label>
    }
    <div className="form-control_content">
      {children}
    </div>
    {invalid && error && typeof error == "string" &&
    
      <span className="form-control_error">{error}</span>
    }
    {
      invalid && error && typeof error !== "string" &&
      <div>
          <div 
        style={{ 
          backgroundColor: ['red', 'orange', "yellowgreen", "green"][error.score - 1],
          height: '10px', 
          width: `${error.score * 25}%` 
        }} 
      />
      <div className="form-control_error">
      Password Strength: {["weak", "medium", "strong", "very strong"][error.score - 1]}
      </div>
        {error.warning && <span className="form-control_error">{error.feedback.warning}</span>}
      {error.feedback.suggestions.map((e, i)=> <p key={i} className="form-control_error">{e}</p>)}  
      </div>
    }
    {hint && !(invalid && error) &&
      <span className={cn('form-control_hint')}>{hint}</span>
    }
  </div>
)

export default FormControl

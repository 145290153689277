import React from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

import FormFields from '../directive/blocks/FormFields'
import FormInput from '../directive/blocks/FormInput'
import { required, chain, strongPassword, equalPasswords } from '../inputs/validators'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import PageTitle from '../directive/blocks/PageTitle'
import Tile from '../directive/blocks/Tile'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import Button from '../directive/blocks/Button'
import PageText from '../directive/blocks/PageText'
import { DIRECTIVE_URL } from '../constants/UrlConstants'
import { showSuccess } from '../common/toastUtils'
import { forAuth } from '../hoc'
import FormError from '../directive/blocks/FormError'
import { useMutation } from '@apollo/react-hooks'
import { CHANGE_OWN_PASSWORD } from '../common/mutations'
import { useTranslation } from 'react-i18next'

const PasswordChange = ({ history }) => {
  const { t } = useTranslation()
  const [changePassword] = useMutation(CHANGE_OWN_PASSWORD)

  const onSubmit = async ({ password, passwordConfirmation }) => {
    const response = await changePassword({ variables: { password, passwordConfirmation } })
    const errors = response.data.changeOwnPassword.errors

    if (errors && errors.length) {
      return { [FORM_ERROR]: errors.join('\n') }
    }

    history.replace(DIRECTIVE_URL)
    showSuccess(t('passwordChange.successMessage'))
  }

  return (
    <Layout>
      <Container>
        <Columns>
          <ColumnTop className="-extra-narrow">
            <PageTitle params={{ text: t('passwordChange.title') }} />
          {history.location.search == "?insecurepassword=true" ? <PageText params={{text: t('passwordChangeInsecure.text')}} />:
            <PageText params={{ text: t('passwordChange.text') }} />}
            <Tile white>
              <Form
                onSubmit={onSubmit}
                render={({ values, submitting, submitError, handleSubmit }) => (
                  <form id="changePasswordForm" onSubmit={handleSubmit}>
                    <FormFields>
                      <Field name="password" validate={chain(required, strongPassword)} type="password">
                        {({ input, meta }) => (
                          <FormInput
                            label={t('form.newPasswordLabel')}
                            placeholder="******"
                            input={input}
                            meta={meta}
                          />
                        )}
                      </Field>
                      <Field
                        name="passwordConfirmation"
                        validate={chain(required, equalPasswords(values.password))}
                        type="password"
                      >
                        {({ input, meta }) => (
                          <FormInput
                            label={t('form.confirmNewPasswordLabel')}
                            placeholder="******"
                            input={input}
                            meta={meta}
                          />
                        )}
                      </Field>
                      {submitError && <FormError className="-with-max-width">{submitError}</FormError>}
                    </FormFields>
                    <ButtonGroup className="-survey-nav">
                      <Button type="submit" isLoading={submitting}>
                        {t('passwordChange.submitButton')}
                      </Button>
                    </ButtonGroup>
                  </form>
                )}
              />
            </Tile>
          </ColumnTop>
        </Columns>
      </Container>
    </Layout>
  )
}

export default forAuth(PasswordChange)

import React from 'react'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { formatISO } from 'date-fns'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import PageTitle from '../directive/blocks/PageTitle'
import Button from '../directive/blocks/Button'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import FormInput from '../directive/blocks/FormInput'
import FormFields from '../directive/blocks/FormFields'
import { capitalize, trim, chain as formatChain } from '../inputs/formatters'
import { required, minLength, emailFormat, chain, strongPassword, datePast, nameValidation, containsDigits, containsSpecials, containsCapitals,   } from '../inputs/validators'
import { POST, REQUESTS, setAuthToken } from '../common/requests'
import DatePicker from '../inputs/DatePicker'
import { DIRECTIVE_URL } from '../constants/UrlConstants'
import FormError from '../directive/blocks/FormError'
import { showSuccess } from '../common/toastUtils'
import SelectorCheckbox from '../directive/blocks/SelectorCheckbox'
import { getShowSignUpHeaderVD } from '../common/organizationTheme'
import VitalDecisionsHeaderInfo from '../directive/blocks/VitalDecisionsHeaderInfo'
import { useTranslation } from 'react-i18next'
import useViewport from '../common/useViewport'
import { getSignupErrorMessage } from './hooks/getSignupErrorMessage'

const showSignUpHeaderVD = getShowSignUpHeaderVD()

const SignUpForm = ({ onSubmit }) => {
  const { t } = useTranslation()
  const url = window.location.href
  const organization = url.replace(/^https?:\/\//, '').split('.')[0]
  const { includeEmail, message } = getSignupErrorMessage(organization)
  const email = includeEmail ? ': [support@mylivingvoice.com](mailto:support@mylivingvoice.com)' : null
  const signupErrorMessage = email ? t(message) + email : t(message)


  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormFields>
              <Field
                name="firstName"
                validate={chain(required, minLength(2), nameValidation)}
                format={formatChain(trim, capitalize)}
                type="text"
                render={({ input, meta }) => <FormInput label={t('form.firstNameLabel')} input={input} meta={meta} />}
              />

              <Field
                name="lastName"
                validate={required}
                format={formatChain(trim, capitalize, nameValidation)}
                render={({ input, meta }) => (
                  <FormInput label={t('form.lastNameLabel')} type="text" input={input} meta={meta} />
                )}
              />
              <Field
                name="dateOfBirth"
                validate={chain(required, datePast)}
                render={({ input, meta }) => (
                  <DatePicker
                    label={t('form.dateOfBirthLabel')}
                    type="text"
                    input={input}
                    meta={meta}
                    showMonthDropdown
                    showYearDropdown
                    maxDate={new Date()}
                    yearDropdownItemNumber={120}
                    scrollableYearDropdown
                  />
                )}
              />
              <Field name="email" validate={chain(required, emailFormat)}>
                {({ input, meta }) => <FormInput label={t('form.emailLabel')} type="text" input={input} meta={meta} />}
              </Field>
              <Field name="password" validate={chain(required, strongPassword)} type="password">
                {({ input, meta }) => <FormInput label={t('form.passwordLabel')} input={input} meta={meta} />}
              </Field>
              <Field type="checkbox" name={'agreement'} validate={chain(required)}>
                {({ input, meta }) => (
                  <>
                    <SelectorCheckbox
                      name={'agreement'}
                      input={input}
                      meta={meta}
                      className="-no-border"
                      label={
                        <span>
                          {t('signUp.termsAgreement.text')} <br />
                          <a href="/terms" target="_blank">
                            {t('signUp.termsAgreement.linkText')}
                          </a>
                        </span>
                      }
                    />
                    {meta.touched && meta.error && (
                      <FormError className="-with-max-width">{t('signUp.termsAgreement.errorText')}</FormError>
                    )}
                  </>
                )}
              </Field>
              {submitError && (
                <FormError className="-with-max-width">
                  {signupErrorMessage}
                </FormError>
              )}
            </FormFields>
            <Button isLoading={submitting} type="submit">
              {t('signUp.submitButton')}
            </Button>
          </form>
        )
      }}
    />
  )
}

const SignUp = ({ history }) => {
  const { t } = useTranslation()
  const { isMobileViewport } = useViewport()

  const signUp = async ({ firstName, lastName, dateOfBirth, email, password }) => {
    const data = await POST(REQUESTS.SIGNUP, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        date_of_birth: formatISO(dateOfBirth, { representation: 'date' }),
      },
    })()

    if (!data.error) {
      const { access_token: accessToken } = data

      setAuthToken(accessToken)
      history.replace(DIRECTIVE_URL)
      showSuccess(t('signUp.successMessage'))
    } else {
      return { [FORM_ERROR]: true }
    }
  }

  return (
    <Layout
      headerClassName={showSignUpHeaderVD && !isMobileViewport ? '-bg-pastel-pink' : null}
      headerAdditionalContent={showSignUpHeaderVD ? <VitalDecisionsHeaderInfo /> : null}
    >
      <Container>
        <Columns>
          <ColumnTop>
            <PageTitle params={{ text: t('signUp.title') }} />
            <SignUpForm onSubmit={signUp} />
          </ColumnTop>
        </Columns>
      </Container>
    </Layout>
  )
}

export default SignUp

import { isAfter } from 'date-fns'
export const zxcvbn = require('zxcvbn');

export const required = (value) => (value ? undefined : (t) => t('validators.required'))
export const minLength = (min) => (value) =>
  value.length >= min ? undefined : (t) => t('validators.minLength', { min, count: min })
export const emailFormat = (value) =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  )
    ? (t) => t('validators.emailFormat')
    : undefined

export const zipValidation = (value) =>
  value && !/^\d{5}([-]|\s*)?(\d{4})?$/.test(value) ? (t) => t('validators.invalidZip') : undefined

export const nameValidation = (value) => value && !/^[A-Za-z\-'\s]+$/i.test(value) ? (t) => t('validators.invalidName') : undefined

export const cityValidation = (value) => value && !/^[A-Za-z\s\-']+$/i.test(value) ? (t) => t('validators.invalidName') : undefined

export const noSpecialCharacters = (value) => value && !/^[0-9a-zA-Z\s#.,-]+$/.test(value) ? (t) => t('validators.invalidCharacters') : undefined

export const containsDigits = (count) => (value) =>
  value && !new RegExp(`(.*[0-9]){${count},}`).test(value) ? (t) => t('validators.containsDigits', { count }) : undefined
export const containsSpecials = (count) => (value) =>
  value && !new RegExp(`(.*[^a-zA-Z0-9]){${count},}`).test(value)
    ? (t) => t('validators.containsSpecials', { count })
    : undefined
export const containsCapitals = (count) => (value) =>
  value && !new RegExp(`(.*[A-Z]){${count},}`).test(value)
    ? (t) => t('validators.containsCapitals', { count })
    : undefined
export const containsLowercase = (count) => (value) =>
  value && !new RegExp(`(.*[a-z]){${count},}`).test(value)
    ? (t) => t('validators.containsLowercase', { count })
    : undefined

 export const passwordStrengthCheck = (value) =>{
  console.log(zxcvbn(value))

 return (value ?  zxcvbn(value).score >= 3 ? undefined : zxcvbn(value)  : undefined)
 }
export const datePast = (value) => (isAfter(value, new Date()) ? (t) => t('validators.datePast') : undefined)

export const chain = (...fns) => (value) => {
  let result = undefined
  fns.forEach((validator) => (result = result || validator(value)))
  return result
}

export const strongPassword = chain(minLength(8), containsDigits(1), containsSpecials(1), containsCapitals(1), containsLowercase(1), passwordStrengthCheck)

export const equalPasswords = (firstValue) => (secondValue) =>
  firstValue === secondValue ? undefined : (t) => t('validators.equalPasswords')

export const dayMonthValidation = (value) => {
  if (value.month && !value.day) {
    return (t) => ({ day: t('validators.dayMonthValidation.chooseDay') })
  }
  if (value.day && !value.month) {
    return (t) => ({ month: t('validators.dayMonthValidation.chooseMonth') })
  }
}

export const multipleChoices = (values, id, min) => {
  let errors = {}
  const question3 = values[id]
  if (question3) {
    const formValues = values[id]
    const selected = Object.keys(formValues).filter((k) => formValues[k])
    const length = selected.length
    if (length < min) {
      errors = { ...errors, formError: (t) => t('validators.multipleChoice', { min, count: min }) }
    }
  } else {
    errors = { ...errors, formError: (t) => t('validators.multipleChoice', { min, count: min }) }
  }
  return errors
}
